@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    scale: 0.3;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
    scale: 0.3;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    scale: 0.3;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}

.fadeIn {
  -webkit-animation: fadeIn 1s 1;
  -moz-animation: fadeIn 1s 1;
  animation: fadeIn 1s 1;
}
