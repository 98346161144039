.dayWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.actionsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
}

.actionsWrapper button {
  min-width: 200px;
}

.emotionsListWrapper {
  margin: 10px 0;
  height: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}

@media (max-width: 650px) {
  .arrowButton {
    display: none;
  }
}
