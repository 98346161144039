.dayWrapper {
  transition: all 0.1s;
  padding: 5px;
  margin: 10px;
  border-radius: 60px;
  width: 30px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  text-decoration: none;
}

.today {
  border: 1px solid var(--text-color);
}

.dayWrapper:hover {
  background-color: var(--text-color);
  color: var(--primary-color);
}
