.userInfoWrapper {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  gap: 15px;
}

.userInfo {
  min-width: 200px;
}

.userInfo li:not(:last-child) {
  margin-bottom: 5px;
}

.username {
  margin-bottom: 20px;
}

.userPhotoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userPhoto {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 150px;
  width: 150px;
  min-width: 150px;
  position: relative;
  border-radius: 50%;
  border: 2px solid var(--text-color);
}

.userPhoto:hover .editPhotoButton {
  opacity: 1;
}

.photoPlaceholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.editPhotoButton {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.5s;
  opacity: 0;
  background-color: var(--main-color);
  border-radius: 50%;
  padding: 6px;
}

.editPhotoButton svg {
  fill: var(--primary-color);
}
