.layout {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.outletWrapper {
  flex: 1;
  padding: 35px;
  overflow: hidden;
}
