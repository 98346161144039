@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
@import "normalize.css/normalize.css";

@import "./reset.css";

html,
body {
  width: 100vw;
  height: 100vh;
  max-height: 100dvh;
  overflow: hidden;
  font-family: "Comfortaa";
  font-size: calc(16px + 0.2vw);
}

#root {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h5 {
  font-weight: 700;
}

h1 {
  font-size: calc(34px + 0.2vw);
}

h2 {
  font-size: calc(30px + 0.2vw);
}

h3 {
  font-size: calc(24px + 0.2vw);
}

h4 {
  font-size: calc(20px + 0.2vw);
}

h5 {
  font-size: calc(18px + 0.2vw);
}

h6 {
  font-size: calc(16px + 0.2vw);
}
