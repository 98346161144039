.form {
  display: flex;
  flex-direction: column;
}

.actionsWrapper {
  display: flex;
  justify-content: center;
}

.actionsWrapper button {
  min-width: 135px;
}
