.light {
  --main-color: #9dff9f;
  --text-color: #000;
  --accent-text-color: #8571ff;
  --primary-color: #fff;
  --alert-color: #ff0000;
  --placeholder-color: #6e6e6e;
}

.dark {
  --main-color: #737373;
  --text-color: #fff;
  --accent-text-color: #8571ff;
  --primary-color: #454545;
  --alert-color: #ff0000;
  --placeholder-color: #bfbfbd;
}
