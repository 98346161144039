.button:disabled {
  cursor: default;
}

.button:disabled svg {
  fill: var(--placeholder-color);
}

.arrow {
  transition: all 0.5s;
}

.right {
  transform: scale(-1, 1);
}
