.emotionWrapper {
  width: 100%;
  max-width: 600px;
  border-bottom: 2px solid black;
  padding: 2px;

  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 5px;
  align-items: center;
}

.emotionWrapper :last-child {
  justify-self: right;
}
