.form {
  display: flex;
  flex-direction: column;
}

.actionsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-between;
}

.actionsWrapper button {
  min-width: 135px;
}
