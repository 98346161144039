.App {
  width: 100%;
  height: 100%;
  color: var(--text-color);
}

svg {
  fill: var(--text-color);
}

/* next two styles added in case we use native buttons but not our button components */
button:disabled {
  cursor: default;
}

button:disabled svg {
  fill: var(--placeholder-color);
}
