.error {
  color: var(--alert-color);
  margin: 0 5px;
}

.error:empty:before {
  /* unicode zero width space character */
  /* to keep error span height */
  content: "\200b";
}
