.modalBody {
  width: 100%;
  height: 100%;
}

.modalBody svg {
  fill: var(--text-color);
}

.modalHeader {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  flex: 1;
  text-align: center;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
