.label {
  margin: 5px;
  display: flex;
  flex-direction: column;
  position: relative;

  color: var(--text-color);
  height: fit-content;

  padding: 6px 10px;
  border: 3px solid var(--text-color);
  border-radius: 20px;
}

.label:has(> :disabled) {
  border-color: var(--placeholder-color);
  color: var(--placeholder-color);
}

.errorLabel {
  border-color: var(--alert-color);
}

.labelText {
  padding: 2px;
  transition: all 0.2s ease-out;
  background-color: var(--primary-color);
  position: absolute;
  transform: translate(0, -1px) scale(1);
  border-radius: 10px;
}

.label:focus-within .labelText,
.activeLabel .labelText {
  width: auto;
  transform: translate(-4px, -17px) scale(0.7);
}

/* we need to separate the same style here to prevent mozilla bugs */
.label:has(> select option:checked) .labelText {
  width: auto;
  transform: translate(-4px, -18px) scale(0.7);
}

.input::placeholder {
  color: var(--placeholder-color);
  transition: all 0.2s ease-out;
  opacity: 0;
}

.label:focus-within .input::placeholder,
.activeLabel .input::placeholder {
  opacity: 1;
}

.input {
  border: none;
  outline: none;
  background-color: transparent;
  resize: none;
  color: inherit;
}

.iconButton {
  position: absolute;
  right: 10px;
  top: 4px;
}

.errorLabelText {
  color: var(--alert-color);
}
