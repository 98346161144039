.button {
  outline: none;
  border: 3px solid var(--button-color);
  border-radius: 40px;
  padding: 10px;
  background-color: var(--button-color);
  color: var(--text-color);
  cursor: pointer;
  box-shadow: none;
  transition: all 0.2s;

  --button-color: var(--main-color);
}

.button:hover,
.button:focus {
  box-shadow: 0px 0px 0px 1px var(--button-color);
}

.button:active {
  box-shadow: none;
}

.outline,
.text {
  background-color: transparent;
}

.text {
  --button-color: var(--text-color);
}

.outline:hover,
.text:hover {
  background-color: var(--button-outline-active-back-color);
}

.button:disabled {
  cursor: default;
  border-color: var(--placeholder-color);
  background-color: var(--placeholder-color);
}

.button:disabled:hover {
  box-shadow: none;
}

.outline:disabled,
.text:disabled,
.outline:disabled:hover,
.text:disabled:hover {
  color: var(--placeholder-color);
  background-color: transparent;
}
