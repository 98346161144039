.settingsForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputsBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.input {
  min-width: 240px;
}
