.emotionsListWrapper {
  width: 100%;
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
