.header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 5px;
  align-items: center;
  padding: 20px 20px 0;
}

.header button:last-child {
  justify-self: right;
}

.loginBtn {
  width: fit-content;
}

.accountBtn {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.iconWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  padding: 1px;
  border-radius: 50%;
  border: 2px solid var(--text-color);
}
