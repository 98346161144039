.footer {
  background: linear-gradient(
    90deg,
    color-mix(in srgb, var(--text-color), transparent 85%) 0%,
    transparent 90%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 0;
}

.footerTopWrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
}

.iconsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.contactUs {
  min-width: 200px;
}

.yearsWrapper {
  width: 100%;
}
