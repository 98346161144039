.calendarFormWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendarFormPaper {
  width: fit-content;
}

.calendarForm {
  display: flex;
}

.calendarControlsWrapper {
  margin: 10px 0;
  min-height: 50vh;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}

@media (max-width: 650px) {
  .arrowButton {
    display: none;
  }
}
