.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigationButton {
  min-width: 150px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
