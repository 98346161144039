.circlesWrapper {
  background-color: var(--primary-color);
  z-index: -2;
  position: absolute;
  width: 100vw;
  height: 100vh;
  max-height: 100dvh;
  overflow: hidden;
}

.circle {
  position: absolute;
  border-radius: 50%;
  background-color: var(--main-color);
  transition: all 1s;
}
