.monthWrapper {
  min-width: 290px;
  min-height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.week {
  display: flex;
  justify-content: space-between;
}

.week:first-child {
  justify-content: end;
}

.week:last-child {
  justify-content: start;
}
